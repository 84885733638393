import React from 'react'
import { connect } from 'react-redux'

import Layout from '../../../components/Layout'

class AdminNavbar extends React.Component {
  render() {
    const cpUser =
      this.props.user &&
      this.props.user.email &&
      (this.props.user.email.includes('@collegepulse.com') ||
        this.props.user.email.includes('demo@'))
    const infogram = 'https://infogram.com/intelligence-insights-live-1h7v4pllpyyd2k0?live'
    return (
      <Layout noFooter active="reports" requireAuth>
        <iframe title="brand_infogram" src={infogram} className="full-page-iframe singleHeader" />
      </Layout>
    )
  }
}

export default connect(
  state => ({
    user: state.user.user,
  }),
  {}
)(AdminNavbar)
